import React, {useRef} from "react"
import {
    AccordionItem,
    AccordionButton
} from "@reach/accordion"
import { SvgPlus } from "../../../svg"
import AccordionSectionPanel from "../accordion-section-panel"

const AccordionSectionItem = ({ index, data, type, setInteractionTrigeredToTrue }) => {
    const ref = useRef(null);

    const handleInteractionTrigeredToTrue = () =>{
        if(window.innerWidth < 1024){
            setTimeout(()=>{
                window.scrollTo({
                    top: ref.current.getBoundingClientRect().top + + document.documentElement.scrollTop,
                    behavior: 'smooth'
                  });
            },600)
        }
        setInteractionTrigeredToTrue()
    }
    return (
        <AccordionItem>
            <AccordionButton ref={ref} className="button--unstyled grid" onClick={() => handleInteractionTrigeredToTrue()} >
                <span className="index">{`${index + 1 < 10 ? '/0' : '/'}${index + 1}`}</span>
                <h3 className="title">{data.title}</h3>
                <span className="icon"><SvgPlus /></span>
            </AccordionButton>
            <AccordionSectionPanel data={data} type={type} />
        </AccordionItem >
    )
}

export default AccordionSectionItem