import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import DynamicImage from "../../../../shared/components/dynamic-image"
import { CSSTransition } from "react-transition-group"
import "./index.scss"

const AccordionItemFigure = ({ data = null, backgroundImage = null, appImage = null, container = false, activeItem = 0 }) => {

    if (container && data) {
        return (
            <div className={`accordion-figure${container ? ' accordion-figure--container' : ''}`}>
                {
                    data.map((itemData, key) => {
                        return (
                            <CSSTransition key={`item-transition-${key}`} in={activeItem === key} timeout={667} appear>
                                <figure key={`item-figure-${key}`} className={`background-image${activeItem === key ? ' background-image--visible' : ''}`}>
                                    <DynamicImage
                                        imageKey={itemData.backgroundImage}
                                        visible={activeItem === key}
                                    />
                                </figure>
                            </CSSTransition>
                        )
                    })
                }
                <div className="iphone-image" >
                    <StaticImage
                        src="../../../../../assets/images/home/section-accordion/iphone-frame-white.png"
                        alt="Iphone frame in white color"
                        placeholder="dominantColor"
                        objectFit="contain"
                    />
                    <div className="app-image-container">
                        {
                            data.map((itemData, key) => {
                                return (
                                    <CSSTransition key={`item-transition-${key}`} in={activeItem === key} timeout={667} appear>
                                        <div key={`item-img-${key}`} className={`app-image${activeItem === key ? ' app-image--visible' : ''}`}>
                                            <DynamicImage
                                                imageKey={itemData.appImage}
                                                visible={activeItem === key}
                                            />
                                        </div>
                                    </CSSTransition>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`accordion-figure${container ? ' accordion-figure--container' : ''}`}>
                <figure className="background-image">
                    <DynamicImage
                        imageKey={backgroundImage}
                    />
                </figure>
                <figure div className="iphone-image">
                    <StaticImage
                        src="../../../../../assets/images/home/section-accordion/iphone-frame-white.png"
                        alt="Iphone frame in white color"
                        placeholder="dominantColor"
                        objectFit="contain"
                    />
                    <div className="app-image-container">
                        <div className="app-image">
                            <DynamicImage
                                imageKey={appImage}
                            />
                        </div>
                    </div>
                </figure>
            </div>
        )
    }
}

export default AccordionItemFigure