import React, { useState } from "react"
import { Accordion } from "@reach/accordion"
import AccordionSectionItem from "./accordion-section-item"
import AccordionItemFigure from "./accordion-item-figure"
import { useInView } from "react-intersection-observer"
import './index.scss'

const AccordionSection = ({ type = "default", items = [] }) => {

    const [activeItem, setActiveItem] = useState(0)
    const [interactionTrigered, setInteractionTrigered] = useState(false)

    const setInteractionTrigeredToTrue = () => {
        if (!interactionTrigered) setInteractionTrigered(true)
    }

    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    return (
        <div ref={ref} className={`accordion-section accordion-section--${type}${!interactionTrigered ? ' onload-animation-not-triggered' : ''}${inView && !interactionTrigered ? ' in-view' : ''}`}>
            <Accordion
                multiple={type === "faqs" ? true : false}
                collapsible={type === "faqs" ? true : false}
                onChange={(index) => {
                    setActiveItem(index)
                }}
            >
                {
                    items.map((itemData, key) => {
                        return (
                            <AccordionSectionItem
                                key={key}
                                index={key}
                                data={itemData}
                                type={type}
                                setInteractionTrigeredToTrue={setInteractionTrigeredToTrue}
                            />
                        )
                    })
                }
            </Accordion >
            {type === 'home' &&
                <AccordionItemFigure data={items} container={true} activeItem={activeItem} />
            }
        </div>
    )

}

export default AccordionSection