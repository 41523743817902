import React, { useEffect, useRef } from "react"
import {
    AccordionPanel,
    useAccordionItemContext
} from "@reach/accordion"
import AccordionItemFigure from "../accordion-item-figure"

const AccordionSectionPanel = ({ data, type }) => {

    const sideAccordionItemPanel = useRef()

    return (
        <AccordionPanel
            ref={sideAccordionItemPanel}
            style={{
                "--accordionPanelMaxHeight": `${useAccordionItemContext().isExpanded && sideAccordionItemPanel.current ? sideAccordionItemPanel.current.scrollHeight : '0'}px`
            }}
            className="grid"
        >
            <h3 className="title">{data.title}</h3>
            <p>{data.content}</p>

            {
                type === 'home' &&
                <AccordionItemFigure backgroundImage={data.backgroundImage} appImage={data.appImage} />
            }
        </AccordionPanel >
    )
}

export default AccordionSectionPanel